<template>
    <div class="stock-image-search-result"
         :style="`--width:${thumbnail.width/2};--height:${thumbnail.height/2};`"
         @mouseover="startPlay" @mouseleave="endPlay"
    >
        <nuxt-link v-if="mode === 'list'" :to="`${image.link}`" target="_blank">
            <div style="overflow:hidden;border-radius:5px;" :style="`height:100%;width:100%;position:relative;`">
                <img :src="thumbnail.url" :alt="image.description" :style="`opacity:${videoVisible ? 0: 1}`" @load="loaded">
                <div style="position:absolute;top:0;left:0;right:0;bottom:0;" class="d-none" :class="{'d-block': videoVisible}">
                    <video-player ref="video" class="thumbnail" :fallback-video-url="image.video_url" :poster-url="image.url" @timeupdate="timeElapsed"/>
                </div>
            </div>
        </nuxt-link>
        <div v-else-if="mode === 'selectable'" style="overflow:hidden;border-radius:5px;" :style="`height:100%;width:100%;position:relative;`" class="selectable" :class="{ selected: selected }" @click="clicked">
            <div v-if="inCart" class="in-cart-badge">
                <b-icon-cart-check/>
            </div>
            <img :src="thumbnail.url" :alt="image.description" @load="loaded">
        </div>
        <div v-if="image.type === 'video'" class="video-timestamp">
            <b-icon-play-circle/>
            <strong>{{ image.max_size }}</strong>
            <span class="time-text">{{ secondsToTimestamp(image.duration) }}</span>
        </div>
        <div class="thumbnail-links">
            <nuxt-link v-if="showSimilarLink" :to="`/search?term=similar to:${image.code}`" class="similar-images-link">
                Similar Stock
            </nuxt-link>
            <nuxt-link v-if="showInAlbumLink && image.album" :to="{ name: 'contributor-album-full', params: {slug: image.contributor.slug, album_slug: image.album.slug, album_id: image.album.id}}" class="similar-images-link">
                View Album
            </nuxt-link>
            <a v-if="showAddToCollection" class="similar-images-link add-to-collection" title="Add to Collection" @click="addToCollections">
                <b-icon-folder-plus/>
            </a>
        </div>
        <div v-if="showNameLink || showImageCode" class="contributor-name" :title="`${image.code} by ${image.contributor.name}`" :class="{ visible: showImageCode }">
            <template v-if="showNameLink">
                <nuxt-link :to="{ name: 'contributor-profile', params: {slug: image.contributor.slug} }">
                    {{ image.contributor.name }}
                </nuxt-link>
                <span v-if="Object.keys(priceTiers).length > 1" class="price-tier-dot" :style="{'background-color': priceTierColor}">&nbsp;</span>
            </template>
            <template v-else-if="showImageCode">
                <nuxt-link :to="`${image.link}`" target="_blank">
                    {{ image.code }}
                </nuxt-link>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VideoPlayer from '~/components/global/VideoPlayer.vue'

export default {
    name: 'StockImageThumbnail',
    components: { VideoPlayer },
    props: {
        image: {
            type: Object,
            default: null
        },
        showInAlbumLink: {
            type: Boolean,
            default: true
        },
        showNameLink: {
            type: Boolean,
            default: true
        },
        showSimilarLink: {
            type: Boolean,
            default: true
        },
        showAddToCollection: {
            type: Boolean,
            default: true
        },
        showImageCode: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'list'
        },
        size: {
            type: String,
            default: 'medium'
        },
        inCart: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hover: false,
            videoVisible: false,
            selected: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            priceTiers: 'data/priceTiers'
        }),
        priceTierColor () {
            let priceTierId = this.image.price_tier_id

            if (this.priceTiers[priceTierId] === undefined || this.priceTiers[priceTierId] === null) {
                priceTierId = 1
            }

            return this.priceTiers[priceTierId].color
        },
        thumbnail () {
            return this.image.thumbnails[this.size]
        }
    },
    mounted () {
        if (this.image.type === 'video' && this.mode === 'list') {
            this.$refs.video.startPlayer({
                autoplay: false,
                controls: false,
                loop: true,
                muted: true,
                poster: this.image.url,
                preload: 'auto',
                sources: [
                    {
                        src: this.image.video_url_hls,
                        type: 'application/x-mpegURL'
                    },
                    {
                        src: this.image.video_url,
                        type: 'video/mp4'
                    }
                ]
            })
        }
    },
    methods: {
        clicked () {
            if (this.mode === 'selectable') {
                const event = this.selected ? 'deselected' : 'selected'
                this.selected = !this.selected
                this.$emit(event, this.image)
            }
        },
        timeElapsed (time) {
            this.videoElapsedTime = time
        },
        startPlay () {
            if (this.image.type === 'video' && this.mode === 'list') {
                this.$refs.video.play()
                this.videoVisible = true
            }
        },
        endPlay () {
            if (this.image.type === 'video' && this.mode === 'list') {
                this.$refs.video.pause()
                this.$refs.video.reset()
                this.videoVisible = false
            }
        },
        loaded () {
            this.$emit('image-loaded')
        },
        addToCollections () {
            if (this.user) {
                this.$nuxt.$emit('addToCollections', this.image)
            } else {
                this.$nuxt.$emit('showAuthModal', {
                    name: 'addToCollections',
                    payload: this.image
                })
            }
        }
    }
}
</script>

<style>
.vjs-poster, .vjs-title-bar, .vjs-text-track-display, .vjs-loading-spinner, .vjs-big-play-button, .vjs-control-bar, .vjs-error-display, .vjs-modal-dialog {
    display: none !important;
}

.video-js.thumbnail {
    width: 100% !important;
    height: 100% !important;
}
</style>
